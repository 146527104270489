import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  body: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    background: 'black'
  },
  logo: {
    width: '10%',
    marginLeft: '2%',
    marginTop: '2%'
  }
});

export default useStyles;
