import { makeStyles } from '@mui/styles';

const useStylesAlert = makeStyles((theme) => ({
  titleAlert: {
    padding: '4% 4% !important',
    [theme.breakpoints.up('xl')]: {
      fontSize: '450% !important'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '300% !important'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '200% !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '150% !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '150% !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '130% !important'
    },
    [theme.breakpoints.down('xxs')]: {
      fontSize: '100% !important'
    }
  },
  textAlert: {
    [theme.breakpoints.up('xl')]: {
      fontSize: '300% !important'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '250% !important'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '180% !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '125% !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '120% !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '100% !important'
    },
    [theme.breakpoints.down('xxs')]: {
      fontSize: '70% !important'
    }
  },
  buttonAlert: {
    margin: '1% !important',
    width: '27%',
    padding: '2% !important',
    [theme.breakpoints.up('xl')]: {
      fontSize: '270% !important'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '200% !important'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '160% !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '115% !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '110% !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '90% !important'
    },
    [theme.breakpoints.down('xxs')]: {
      fontSize: '70% !important'
    }
  }
}));

export default useStylesAlert;
