/* eslint-disable import/prefer-default-export */
const statuses = {
  PENDING: 'pending',
  PENDING_APPROVAL: 'pendingApproval',
  BACKORDER: 'backorder'
};

export function translateStatus(status) {
  if (status === statuses.PENDING) {
    return 'Pendiente';
  }

  if (status === statuses.PENDING_APPROVAL) {
    return 'Por Aprobar';
  }

  if (status === statuses.BACKORDER) {
    return 'Backorder';
  }

  return 'Desconocido';
}
