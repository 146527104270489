import keyMirror from 'keymirror';

const SHIPPING_METHODS = keyMirror({
  PICK_UP: null,
  FLASH: null,
  EXPRESS: null,
  SAME_DAY: null,
  SCHEDULED: null,
  STANDARD: null
});

export function translateShippingMethod(shippingMethod) {
  switch (shippingMethod) {
    case SHIPPING_METHODS.PICK_UP:
      return '🚶 Retiro en tienda';
    case SHIPPING_METHODS.FLASH:
      return '⚡ Express';
    case SHIPPING_METHODS.EXPRESS:
      return '⚡ Express';
    case SHIPPING_METHODS.SAME_DAY:
      return '☀️ Same Day';
    case SHIPPING_METHODS.SCHEDULED:
      return '🕒 Programado';
    case SHIPPING_METHODS.STANDARD:
      return '🚚 Estandar';
    default:
      return '';
  }
}

export const SHIPPING_METHOD_PRIORITY = [
  SHIPPING_METHODS.FLASH,
  SHIPPING_METHODS.EXPRESS,
  SHIPPING_METHODS.SAME_DAY,
  SHIPPING_METHODS.PICK_UP,
  SHIPPING_METHODS.STANDARD,
  SHIPPING_METHODS.SCHEDULED
];

export const SHIPPING_METHOD_ORDER_PRIORITY = [
  SHIPPING_METHODS.PICK_UP,
  SHIPPING_METHODS.EXPRESS
];
