import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#f05c24'
    },
    secondary: {
      main: '#f44336'
    }
  },
  breakpoints: {
    values: {
      xxs: 500,
      xs: 900,
      sm: 1280,
      md: 1920,
      lg: 2560,
      xl: 3840
    }
  }
});

export default theme;
