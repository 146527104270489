import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const { REACT_APP_BACKEND_URL } = process.env;

export default function Login() {
  const navigate = useNavigate();

  const loginDevice = useCallback(async () => {
    const deviceId = { id: localStorage.getItem('deviceId') };

    if (!deviceId.id) {
      navigate('/register', { replace: true });
      return undefined;
    }

    const response = await fetch(`${REACT_APP_BACKEND_URL}/devices/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(deviceId)
    });
    if (!response.ok) {
      localStorage.removeItem('deviceId');
      localStorage.removeItem('token');
      navigate('/register', { replace: true });
      return undefined;
    }
    return response.json();
  }, [navigate]);

  useEffect(() => {
    async function getData() {
      const token = await loginDevice();
      localStorage.setItem('token', token.token);
      navigate('/orders', { replace: true });
    }

    getData();
  }, [loginDevice, navigate]);

  return <div />;
}
