import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import useStyles from '../styles/RegisterStyle';
import useStylesAlert from '../styles/AlertsStyle';

function Register() {
  const navigate = useNavigate();
  const classes = useStyles();
  const classesAlert = useStylesAlert();
  const [deviceId, setDeviceId] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let currentDeviceId = localStorage.getItem('deviceId');

    if (!currentDeviceId) {
      currentDeviceId = uuidv4();
      localStorage.setItem('deviceId', currentDeviceId);
    }

    setDeviceId(currentDeviceId);
  }, []);

  const goToLogin = () => {
    navigate('/', { replace: true });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid>
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.text}>
            El ID de este dispositivo es: {deviceId}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={handleClickOpen}
          >
            Ingresar
          </Button>
        </CardActions>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className={classesAlert.titleAlert}
          id="alert-dialog-title"
        >
          Ingresar
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classesAlert.textAlert}
            id="alert-dialog-description"
          >
            ¿Estás seguro que estás registrado?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classesAlert.buttonAlert}
            variant="contained"
            color="secondary"
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            className={classesAlert.buttonAlert}
            variant="contained"
            onClick={goToLogin}
            autoFocus
          >
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default Register;
