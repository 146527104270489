import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  bodyTable: {
    width: '70%',
    padding: '3%',
    [theme.breakpoints.down('xs')]: {
      width: '100% !important'
    }
  },
  bodyOrders: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  bodyNumber: {
    width: '30%',
    padding: '3%',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100% !important'
    }
  },
  textNumber: {
    [theme.breakpoints.up('xl')]: {
      fontSize: '600% !important'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '350% !important'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '250% !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '170% !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '150% !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '120% !important'
    },
    [theme.breakpoints.down('xxs')]: {
      fontSize: '100% !important'
    }
  },
  number: {
    color: '#f05c24',
    [theme.breakpoints.up('xl')]: {
      fontSize: '3000% !important'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '1800% !important'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '1200% !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '800% !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '700% !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '700% !important'
    },
    [theme.breakpoints.down('xxs')]: {
      fontSize: '500% !important'
    }
  },
  table: {
    width: '20%',
    padding: '1% !important',
    lineHeight: 'normal !important',
    [theme.breakpoints.up('xl')]: {
      fontSize: '400% !important'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '225% !important'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '180% !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '125% !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '110% !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '100% !important'
    },
    [theme.breakpoints.down('xxs')]: {
      fontSize: '70% !important'
    }
  },
  dateLastUpdated: {
    color: 'white',
    display: 'flex',
    marginLeft: '70% !important',
    [theme.breakpoints.up('xl')]: {
      fontSize: '400% !important'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '250% !important'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '180% !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '125% !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '110% !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '100% !important'
    },
    [theme.breakpoints.down('xxs')]: {
      fontSize: '90% !important'
    }
  },
  image: {
    [theme.breakpoints.up('xl')]: {
      width: '70%'
    },
    [theme.breakpoints.down('xl')]: {
      width: '65%'
    },
    [theme.breakpoints.down('lg')]: {
      width: '65%'
    },
    [theme.breakpoints.down('md')]: {
      width: '60%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '60%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '50%'
    },
    [theme.breakpoints.down('xxs')]: {
      width: '40%'
    }
  },
  priorityWarning: {
    animation: '$priorityEffect 2s linear infinite'
  },
  '@keyframes priorityEffect': {
    '0%': {
      opacity: 0
    },
    '25%': {
      opacity: 0.5
    },
    '50%': {
      opacity: 1
    },
    '75%': {
      opacity: 0.5
    },
    '100%': {
      opacity: 0
    }
  }
}));

export default useStyles;
