import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '40%',
    background: 'white',
    marginLeft: '30%',
    marginTop: '10%'
  },
  button: {
    color: 'white',
    padding: '1% !important',
    width: '20% !important',
    margin: '3% !important',
    [theme.breakpoints.up('xl')]: {
      fontSize: '400% !important'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '250% !important'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '180% !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '125% !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '120% !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '100% !important'
    },
    [theme.breakpoints.down('xxs')]: {
      fontSize: '70% !important'
    }
  },
  text: {
    padding: '2% !important',
    [theme.breakpoints.up('xl')]: {
      fontSize: '400% !important'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '250% !important'
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '180% !important'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '125% !important'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '120% !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '100% !important'
    },
    [theme.breakpoints.down('xxs')]: {
      fontSize: '70% !important'
    }
  }
}));

export default useStyles;
