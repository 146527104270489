import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import Register from '../components/Register';
import Login from '../components/Login';
import Orders from '../components/Orders';
import useStyles from '../styles/AppStyle';
import logo from '../resources/images/logo.png';
import '../styles/style.css';

function App() {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      <Box>
        <img className={classes.logo} src={logo} alt="" />
      </Box>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/orders" element={<Orders />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
