import { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import useStyles from '../styles/OrdersStyle';
import useStylesAlert from '../styles/AlertsStyle';
import notificationSound from '../resources/audios/notification.mp3';
import priorityNotificationSound from '../resources/audios/priorityNotification.mp3';
import { translateStatus } from '../lib/status-names';
import getSystemNameImage from '../lib/system-names';

import {
  translateShippingMethod,
  SHIPPING_METHOD_ORDER_PRIORITY,
  SHIPPING_METHOD_PRIORITY
} from '../lib/shipping-methods';

const { REACT_APP_BACKEND_URL } = process.env;
const ONE_MINUTE_IN_MILLISECONDS = 60000;
const ORDERS_REQUEST_LIMIT = 100;

function dataForTable(orders) {
  return orders.map((order) => ({
    ...order,
    status: translateStatus(order.status)
  }));
}

function styleTable(shippingMethod) {
  const isPrioritary = SHIPPING_METHOD_ORDER_PRIORITY.includes(shippingMethod);

  return {
    textAlign: 'center',
    background: isPrioritary ? '#DCDCDC' : 'white'
  };
}

function sortOrdersByShippingMethod(orderA, orderB) {
  const priorityA = SHIPPING_METHOD_PRIORITY.indexOf(orderA.shippingMethod);
  const priorityB = SHIPPING_METHOD_PRIORITY.indexOf(orderB.shippingMethod);

  if (priorityA < priorityB) {
    return -1;
  }

  if (priorityB < priorityA) {
    return 1;
  }

  return 0;
}

export default function Orders() {
  const classes = useStyles();
  const classesAlert = useStylesAlert();
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [open, setOpen] = useState(true);
  const notificationAudio = useMemo(() => new Audio(notificationSound), []);
  const priorityAudio = useMemo(() => new Audio(priorityNotificationSound), []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const checkNewOrder = useCallback(
    async (newList, oldList) => {
      const oldListIndexes = oldList.map((order) => order.id);
      const newShippingMethodsList = newList
        .filter((data) => !oldListIndexes.includes(data.id))
        .map((filteredOrder) => filteredOrder.shippingMethod);

      if (newList.length !== oldList.length || newShippingMethodsList.length) {
        const priority = newShippingMethodsList.some((shippingMethod) =>
          SHIPPING_METHOD_ORDER_PRIORITY.includes(shippingMethod)
        );

        if (priority) {
          priorityAudio.loop = true;
          priorityAudio.play();
        } else {
          priorityAudio.pause();
          priorityAudio.currentTime = 0;

          notificationAudio.play();
        }
      }
    },
    [notificationAudio, priorityAudio]
  );

  const getOrderData = useCallback(async () => {
    const tokenJson = localStorage.getItem('token');
    const allOrders = [];

    for (let skip = 0; skip !== -1; skip += ORDERS_REQUEST_LIMIT) {
      // eslint-disable-next-line no-await-in-loop
      const response = await fetch(
        `${REACT_APP_BACKEND_URL}/orders?skip=${skip}&limit=${ORDERS_REQUEST_LIMIT}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenJson}`
          }
        }
      );

      if (!response.ok) {
        navigate('/', { replace: true });
      }
      // eslint-disable-next-line no-await-in-loop
      const responseJson = await response.json();
      const newOrderList = dataForTable(responseJson.data);
      allOrders.push(...newOrderList);

      if (responseJson.total <= responseJson.limit + skip) {
        break;
      }
    }
    allOrders.sort(sortOrdersByShippingMethod);

    setOrders((prevState) => {
      checkNewOrder(allOrders, prevState);
      return allOrders;
    });
  }, [navigate, checkNewOrder]);

  useEffect(() => {
    getOrderData();

    const interval = setInterval(() => {
      getOrderData();
    }, ONE_MINUTE_IN_MILLISECONDS);

    return () => clearInterval(interval);
  }, [getOrderData]);

  const dataAccess = (
    <div className={classes.bodyTable}>
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.table}>
                  Orden
                </TableCell>
                <TableCell align="center" className={classes.table}>
                  Sistema
                </TableCell>
                <TableCell align="center" className={classes.table}>
                  Cliente
                </TableCell>
                <TableCell align="center" className={classes.table}>
                  Estado
                </TableCell>
                <TableCell align="center" className={classes.table}>
                  Tipo
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((orderData) => (
                <TableRow
                  key={orderData.id}
                  className={
                    (SHIPPING_METHOD_ORDER_PRIORITY.includes(
                      orderData.shippingMethod
                    ) &&
                      classes.priorityWarning) ||
                    ''
                  }
                >
                  <TableCell
                    className={classes.table}
                    sx={styleTable(orderData.shippingMethod)}
                  >
                    {!orderData.wmsData
                      ? orderData.sourceOrder.orderNumber ||
                        orderData.sourceOrder.orderId
                      : `${orderData.wmsData.wmsId} (WMS)`}
                  </TableCell>
                  <TableCell
                    className={classes.table}
                    sx={styleTable(orderData.shippingMethod)}
                  >
                    <img
                      className={classes.image}
                      src={getSystemNameImage(orderData.sourceOrder.systemName)}
                      alt=""
                    />
                  </TableCell>
                  <TableCell
                    className={classes.table}
                    sx={styleTable(orderData.shippingMethod)}
                  >
                    {orderData.sellerName}
                  </TableCell>
                  <TableCell
                    className={classes.table}
                    sx={styleTable(orderData.shippingMethod)}
                  >
                    {orderData.status}
                  </TableCell>
                  <TableCell
                    className={classes.table}
                    sx={styleTable(orderData.shippingMethod)}
                  >
                    {translateShippingMethod(orderData.shippingMethod)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </div>
  );

  const totalOrders = (
    <div className={classes.bodyNumber}>
      <Card>
        <Typography className={classes.number}>{orders.length}</Typography>
        <Typography className={classes.textNumber}>
          Órdenes pendientes
        </Typography>
      </Card>
    </div>
  );

  return (
    <div>
      <Typography className={classes.dateLastUpdated}>
        Última actualización: {DateTime.now().toFormat('f')}
      </Typography>
      <div className={classes.bodyOrders}>
        {totalOrders}
        {dataAccess}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          className={classesAlert.titleAlert}
          id="alert-dialog-title"
        >
          Activar sonido
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classesAlert.textAlert}
            id="alert-dialog-description"
          >
            Presiona el botón para habilitar el sonido
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className={classesAlert.buttonAlert}
            variant="contained"
            color="primary"
            onClick={handleClose}
            autoFocus
          >
            Habilitar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
